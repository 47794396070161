/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {forwardRef, RefObject, useEffect, useState} from 'react'
import {Divider, Form, Space, Spin, Tag, Typography} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../components/common/BasicTableModal'
import dayjsKorean from '../../../../libs/DayjsKorean'
import {ClockCircleOutlined, FileOutlined, PushpinOutlined} from '@ant-design/icons'
import {IData} from '../../../../api/datas'
import styled from 'styled-components'
import {getFileCheck} from '../../../../api/files'
import downloadFile from '../../../../libs/downloadFile'

interface ModalProps {
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<IData>
  onAction: (type: ActionType, record: Partial<IData>) => void | Promise<void>
}

function Show({record}: {record: IData}) {
  const [fileNames, setFileNames] = useState<string[]>([])
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false)

  async function importFileNames() {
    const fileNames: string[] = []
    for (const path of record.files) {
      const slashSplitedPath = path.split('/')
      const {fileName} = await getFileCheck({path: slashSplitedPath[slashSplitedPath.length - 1]})
      fileNames.push(fileName)
    }
    setFileNames(fileNames)
  }

  useEffect(() => {
    importFileNames()
  }, [])

  return (
    <Spin spinning={downloadLoading} tip="다운로드 중입니다...취소를 원하시면 새로고침을 눌러주세요." size="large">
      <div style={{padding: '0 28px 28px 28px'}}>
        <div>
          <Space>
            {record.isFixation ? (
              <StyledTag icon={<PushpinOutlined />} color="#d54d43">
                중요자료
              </StyledTag>
            ) : (
              <StyledTag color={process.env.REACT_APP_THEME_COLOR}>일반자료</StyledTag>
            )}
            <Typography.Title level={4}>{record.title}</Typography.Title>
          </Space>
        </div>
        <Typography.Text style={{color: 'gray', fontSize: 12}}>
          <ClockCircleOutlined /> {`${dayjsKorean(record.createdAt).format('YYYY/MM/DD')}`}
        </Typography.Text>
        <Divider style={{margin: '20px 0'}} />
        <HtmlContainer style={{minHeight: 200}} dangerouslySetInnerHTML={{__html: record.content}} />
        <Divider style={{margin: '20px 0'}} />
        <div>
          {!!record.files &&
            record.files.map((file, index) => {
              const splitedFileUrl = file.split('/')
              const fileNameByPath = splitedFileUrl[splitedFileUrl.length - 1]
              return (
                <>
                  <a
                    onClick={(e) => {
                      e.preventDefault()
                      downloadFile(file, fileNames[index] || fileNameByPath, setDownloadLoading)
                    }}
                  >
                    <FileOutlined />
                    {fileNames[index] || fileNameByPath}
                  </a>
                  <br />
                </>
              )
            })}
        </div>
      </div>
    </Spin>
  )
}

const DataModal = forwardRef<BasicTableModalRef, ModalProps>((props, ref) => {
  const [form] = Form.useForm()
  const {title = '자료실 ', onAction, actions = ['show', 'add', 'edit', 'delete']} = props

  return (
    <BasicTableModal
      ref={ref}
      actions={actions}
      title={title}
      form={form}
      width={800}
      onAction={onAction}
      render={(type, record) => (
        <Form form={form}>
          <Show record={record} />
        </Form>
      )}
    />
  )
})

const StyledTag = styled(Tag)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  font-weight: 500;
  margin-right: 0;
  margin-bottom: 0.7em;
`

const HtmlContainer = styled.div`
  img {
    max-width: 780px;
  }
`

export default DataModal
