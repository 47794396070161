import React, {useState, useEffect, useRef, useContext} from 'react'
import {
  Button,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  List,
  Select,
  Space,
  Tag,
  Typography,
  Pagination,
  message,
  Modal
} from 'antd'
import {ActionType, BasicTableModalRef} from '../../../components/common/BasicTableModal'
import PageContext from '../../../contexts/PageContext'
import {getOrders, IOrder, postOrderIteReplicate, postOrderReplicate} from '../../../api/orders'
import OrderModal from './components/orderModal'
import ImageView from '../../../components/ImageView'
import styled from 'styled-components'
import dayjsKorean from '../../../libs/DayjsKorean'
import {useRecoilState, useRecoilValue} from 'recoil'
import {cartTotalState} from '../../../states/cartTotal.state'
import {getAppCart} from '../../../api/cart'
import dayjs from 'dayjs'
import {userState} from '../../../states/user.state'
import OrderReplicateModal from './components/orderReplicateModal'
import {CheckCircleOutlined} from '@ant-design/icons'
import {useNavigate} from 'react-router'

function Order() {
  const [list, setList] = useState<any>([])
  const [modalActions, setModalActions] = useState<ActionType[]>(['show'])
  const {state, apiHandler} = useContext(PageContext)
  const modalRef = useRef<BasicTableModalRef>(null)
  const replicateModalRef = useRef<BasicTableModalRef>(null)
  const [data, setData] = useState<IOrder[]>([])
  const [vendor, setVendor] = useState<any[]>([])
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(false)
  const [, setCartTotal] = useRecoilState(cartTotalState)
  const user: any = useRecoilValue(userState)
  const navigator = useNavigate()

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)

  async function onFetch(startDate?, endDate?, status?, vendorIds?, page = currentPage, size = pageSize) {
    setLoading(true)
    let option: any = {
      start: (page - 1) * size,
      perPage: size
    }

    if (startDate && endDate) option = {...option, startDate, endDate}
    if (status) option = {...option, status}
    if (vendorIds) option = {...option, vendorIds}

    const {data, vendor, total} = await getOrders(option)
    setData(data)
    setVendor(vendor)
    setTotal(total)

    const {total: cartTotal} = await getAppCart()
    setCartTotal(cartTotal)

    setLoading(false)
  }

  async function showTableModal(data) {
    if (modalRef && modalRef.current) {
      modalRef.current.showModal({type: 'show', record: data.id})
    }
  }

  async function onModalAction() {
    await apiHandler(state.params)
  }

  useEffect(() => {
    onFetch()
  }, [])

  useEffect(() => form.setFieldsValue(data), [data])

  useEffect(() => {
    if (data) {
      const storedIds: any = []
      const filtered: any = []
      data.forEach((order) => {
        if (!storedIds.includes(order.orderId)) {
          storedIds.push(order.orderId)
          filtered.push({
            orderId: order.orderId,
            orderProductId: order.orderProductId,
            orderStatus: order.orderStatus,
            trackingNumber: order.trackingNumber,
            parcel: order.parcel,
            buyerName: order.buyerName,
            amount: order.amount,
            quantity: order.quantity,
            createdAt: order.createdAt,
            infos: [
              {
                orderStatus: order.orderStatus,
                quantity: order.quantity,
                couponInfo: order.couponInfo,
                productInfo: order.productInfo,
                iteOptionInfo: order.iteOptionInfo,
                basicOptionInfo: order.basicOptionInfo,
                deliveryInfo: order.deliveryInfo
              }
            ]
          })
          return true
        } else {
          const idx = filtered.findIndex(({orderId}) => orderId === order.orderId)
          filtered[idx].infos.push({
            quantity: order.quantity,
            couponInfo: order.couponInfo,
            productInfo: order.productInfo,
            iteOptionInfo: order.iteOptionInfo,
            basicOptionInfo: order.basicOptionInfo,
            deliveryInfo: order.deliveryInfo
          })
          return false
        }
      })
      setList([...filtered])
    }
  }, [data])

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page)
    if (pageSize) setPageSize(pageSize)
    onFetch(undefined, undefined, undefined, undefined, page, pageSize)
  }

  return (
    <Space direction="vertical" style={{width: '100%'}}>
      <Space>
        <DatePicker.RangePicker
          onChange={(dateRange: any) => {
            if (dateRange)
              onFetch(dayjs(dateRange[0] as any).format('YYYY-MM-DD'), dayjs(dateRange[1] as any).format('YYYY-MM-DD'))
            else onFetch()
          }}
        />
        <Select style={{width: 200}} placeholder="필터" allowClear onChange={(val) => onFetch(null, null, val)}>
          <Select.Option value="registered">주문 완료</Select.Option>
          <Select.Option value="confirmed">주문 확인 및 배송 준비</Select.Option>
          <Select.Option value="inspection">출고완료</Select.Option>
        </Select>
        <Select style={{width: 200}} placeholder="납품처" allowClear onChange={(val) => onFetch(null, null, null, val)}>
          {vendor.map((vendor) => (
            <Select.Option key={vendor.id} value={vendor.id}>
              {vendor.code}
            </Select.Option>
          ))}
        </Select>
      </Space>
      <StyledList
        loading={loading}
        itemLayout="vertical"
        dataSource={list}
        renderItem={(item: any, index: number) => (
          <StyledListItem key={item.orderId}>
            <List.Item.Meta
              title={
                <>
                  <TitleContainer>
                    <div>
                      <OrderId>{item?.orderId + ' · ' + dayjsKorean(item?.createdAt).format('YYYY/MM/DD')}</OrderId>
                    </div>
                    <Space>
                      <Button
                        onClick={(e) => {
                          e.preventDefault()
                          setModalActions(['show'])
                          showTableModal({id: item.orderId})
                        }}
                      >
                        주문상세
                      </Button>
                      <Button
                        onClick={async (e) => {
                          try {
                            if (item.infos[0].productInfo?.orderType === 'ITE')
                              await postOrderIteReplicate(item.orderId)
                            else await postOrderReplicate(item.orderId)
                            Modal.confirm({
                              icon: <CheckCircleOutlined style={{color: '#52c41a'}} />,
                              title: '장바구니에 추가되었습니다.',
                              cancelText: '장바구니로 바로가기',
                              okText: '확인',
                              onCancel: () => navigator('../cart')
                            })
                          } catch (e) {
                            message.error({content: '해당 상품의 수량이 부족합니다.'})
                          }
                        }}
                      >
                        동일 옵션 재주문
                      </Button>
                    </Space>
                  </TitleContainer>
                  <Divider style={{margin: '15px 0'}} />
                </>
              }
              description={
                <>
                  <Space style={{margin: '-10px 0 5px 0'}}>
                    <Space direction="vertical">
                      {item?.orderStatus === 'registered' && (
                        <StatusTag color={process.env.REACT_APP_THEME_COLOR}>주문 접수 요청됨</StatusTag>
                      )}
                      {item?.orderStatus === 'confirmed' && (
                        <StatusTag color={process.env.REACT_APP_THEME_COLOR}>주문 접수 완료</StatusTag>
                      )}
                      {item?.orderStatus === 'inspection' && (
                        <StatusTag color={process.env.REACT_APP_THEME_COLOR}>검수 완료</StatusTag>
                      )}
                      {item?.orderStatus === 'cancelled' && <StatusTag color="gray">주문 취소됨</StatusTag>}
                    </Space>
                    {user.isPricePublic && (
                      <>
                        <div>총 결제금액</div>
                        <div
                          style={{
                            marginRight: -5,
                            fontSize: 18,
                            color: process.env.REACT_APP_THEME_COLOR,
                            fontWeight: 'bold'
                          }}
                        >
                          {item?.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </div>
                        <div style={{marginTop: 5}}>원</div>
                      </>
                    )}
                  </Space>
                  {item.infos?.map((item, index) => (
                    <>
                      {!!index && <Divider />}
                      <Space size="small" style={{width: '100%'}}>
                        <ImageView src={item?.productInfo?.titleImage} width={123} height={123} isBorder />
                        <Space direction="vertical" style={{width: 150, margin: '0 30px 0 10px'}}>
                          <SubTitle>{item?.productInfo?.brand}</SubTitle>
                          <Title level={4}>{item?.productInfo?.name}</Title>
                        </Space>
                        <Descriptions
                          bordered
                          style={{width: 200, marginRight: 30}}
                          column={1}
                          size="small"
                          title="주문 정보"
                        >
                          <Descriptions.Item label="수량">{item?.quantity}개</Descriptions.Item>
                          <Descriptions.Item label="배송 방법">{item?.deliveryInfo?.deliveryType}</Descriptions.Item>
                        </Descriptions>
                        {!item?.basicOptionInfo?.color &&
                        !item?.basicOptionInfo?.hook &&
                        !item?.basicOptionInfo?.charge &&
                        !item?.basicOptionInfo?.receivers?.length ? (
                          <></>
                        ) : (
                          <Descriptions bordered style={{width: 350}} column={2} size="small" title="옵션">
                            {item?.basicOptionInfo?.color && (
                              <Descriptions.Item label="컬러">{item?.productInfo?.color}</Descriptions.Item>
                            )}
                            {item?.basicOptionInfo?.hooks?.length && (
                              <Descriptions.Item label="후크">{item?.basicOptionInfo?.hooks[0].name}</Descriptions.Item>
                            )}
                            {item?.basicOptionInfo?.charges?.length && (
                              <Descriptions.Item label="충전기">
                                {item?.basicOptionInfo?.charges.map(({name}) => <Tag>{name}</Tag>)}
                              </Descriptions.Item>
                            )}
                            {item?.basicOptionInfo?.receivers?.length && (
                              <Descriptions.Item label="리시버">
                                {item?.basicOptionInfo?.receivers[0].product &&
                                  item?.basicOptionInfo?.receivers
                                    ?.filter((receiver) => receiver.product?.productReceiverDirection)
                                    .map((receiver) => (
                                      <Tag>
                                        <>
                                          {receiver.product?.productReceiverDirection?.name}
                                          {' / '}
                                          {receiver.product?.productReceiverLength?.name}
                                          {receiver.product?.productReceiverPower &&
                                            ` / ${receiver?.product.productReceiverPower?.name}`}
                                        </>
                                      </Tag>
                                    ))}
                                {!item?.basicOptionInfo?.receivers[0].product &&
                                  item?.basicOptionInfo?.receivers?.map((receiver) => (
                                    <Tag>
                                      <>
                                        {receiver.productReceiverDirection.name}
                                        {' / '}
                                        {receiver.productReceiverLength.name}
                                        {receiver.productReceiverPower && ` / ${receiver?.productReceiverPower.name}`}
                                      </>
                                    </Tag>
                                  ))}
                              </Descriptions.Item>
                            )}
                          </Descriptions>
                        )}
                      </Space>
                    </>
                  ))}
                </>
              }
            />
          </StyledListItem>
        )}
      />
      <Pagination current={currentPage} pageSize={pageSize} total={total} onChange={handlePageChange} />
      <OrderModal actions={modalActions} ref={modalRef} onAction={onModalAction} />
      <OrderReplicateModal actions={modalActions} ref={replicateModalRef} onAction={onModalAction} />
    </Space>
  )
}

export default Order

const StyledList = styled(List<any>)`
  .ant-list-item {
    border: none;
  }
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled(Typography.Title)`
  margin: -8px 0;
  font-size: 20px;
`

const OrderId = styled.div`
  font-size: 16px;
  color: #4e4e4e;
`

const SubTitle = styled.div`
  font-weight: 500;
  font-size: 13px;
  color: #4e4e4e;
`

const StyledListItem = styled(List.Item)`
  border: 1px solid rgba(0, 0, 0, 0.06) !important;
  background: #f8fafc;
  border-radius: 10px;
  padding: 20px 25px 15px 25px;
  margin: 30px 0;
`
const StatusTag = styled(Tag)`
  padding: 2px 7px;
  font-weight: 600;
  font-size: 15px;
`
